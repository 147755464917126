// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-games-tsx": () => import("./../../../src/pages/projects/games.tsx" /* webpackChunkName: "component---src-pages-projects-games-tsx" */),
  "component---src-pages-projects-mobile-tsx": () => import("./../../../src/pages/projects/mobile.tsx" /* webpackChunkName: "component---src-pages-projects-mobile-tsx" */),
  "component---src-pages-projects-other-tsx": () => import("./../../../src/pages/projects/other.tsx" /* webpackChunkName: "component---src-pages-projects-other-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/ProjectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */)
}

